export const normalizeToTableContractors = (serverContractor) => {
  return {
    id: serverContractor.id,
    code: serverContractor.contractor_code,
    inn: serverContractor.inn,
    type: serverContractor.contractor_type,
    title: serverContractor.title,
  }
}

export const normalizeToSingleContractor = (serverContractor) => {
  const organization = serverContractor.contractor.contractor_type
    ? serverContractor.contractor[
        serverContractor.contractor.contractor_type.value
      ]
    : null
  return {
    id: serverContractor.id,
    code: serverContractor.contractor_code,
    inn: serverContractor.inn,
    contractor: {
      type: serverContractor.contractor.contractor_type,
      organization,
    },
    bankAccount: {
      bic: serverContractor.bank_account?.bic || '',
      bank_name: serverContractor.bank_account?.bank_name || '',
      account_number: serverContractor.bank_account?.account_number || '',
      corr_account_number:
        serverContractor.bank_account?.corr_account_number || '',
      account_name: serverContractor.bank_account?.account_name || '',
    },
  }
}
