export const AddressAutocomplete = () => import('../../components/AddressAutocomplete.vue' /* webpackChunkName: "components/address-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const AppBottomFormBtns = () => import('../../components/AppBottomFormBtns.vue' /* webpackChunkName: "components/app-bottom-form-btns" */).then(c => wrapFunctional(c.default || c))
export const AppBottomSheet = () => import('../../components/AppBottomSheet.vue' /* webpackChunkName: "components/app-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const AppError = () => import('../../components/AppError.vue' /* webpackChunkName: "components/app-error" */).then(c => wrapFunctional(c.default || c))
export const AppInfiniteDataLoader = () => import('../../components/AppInfiniteDataLoader.vue' /* webpackChunkName: "components/app-infinite-data-loader" */).then(c => wrapFunctional(c.default || c))
export const AppMenuList = () => import('../../components/AppMenuList.vue' /* webpackChunkName: "components/app-menu-list" */).then(c => wrapFunctional(c.default || c))
export const AppMenuListDivider = () => import('../../components/AppMenuListDivider.vue' /* webpackChunkName: "components/app-menu-list-divider" */).then(c => wrapFunctional(c.default || c))
export const AppMenuListItem = () => import('../../components/AppMenuListItem.vue' /* webpackChunkName: "components/app-menu-list-item" */).then(c => wrapFunctional(c.default || c))
export const AppMobileRangePicker = () => import('../../components/AppMobileRangePicker.vue' /* webpackChunkName: "components/app-mobile-range-picker" */).then(c => wrapFunctional(c.default || c))
export const AppModalLineWrapper = () => import('../../components/AppModalLineWrapper.vue' /* webpackChunkName: "components/app-modal-line-wrapper" */).then(c => wrapFunctional(c.default || c))
export const AppMultipleActionsBar = () => import('../../components/AppMultipleActionsBar.vue' /* webpackChunkName: "components/app-multiple-actions-bar" */).then(c => wrapFunctional(c.default || c))
export const AppRangePicker = () => import('../../components/AppRangePicker.vue' /* webpackChunkName: "components/app-range-picker" */).then(c => wrapFunctional(c.default || c))
export const AppScrollToTopBtn = () => import('../../components/AppScrollToTopBtn.vue' /* webpackChunkName: "components/app-scroll-to-top-btn" */).then(c => wrapFunctional(c.default || c))
export const AppTableInfiniteLoading = () => import('../../components/AppTableInfiniteLoading.vue' /* webpackChunkName: "components/app-table-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const BeyondvioletMap = () => import('../../components/BeyondvioletMap.vue' /* webpackChunkName: "components/beyondviolet-map" */).then(c => wrapFunctional(c.default || c))
export const ChipWithType = () => import('../../components/ChipWithType.vue' /* webpackChunkName: "components/chip-with-type" */).then(c => wrapFunctional(c.default || c))
export const CopyToClipboard = () => import('../../components/CopyToClipboard.vue' /* webpackChunkName: "components/copy-to-clipboard" */).then(c => wrapFunctional(c.default || c))
export const MoreBtn = () => import('../../components/MoreBtn.vue' /* webpackChunkName: "components/more-btn" */).then(c => wrapFunctional(c.default || c))
export const MultipleActionBar = () => import('../../components/MultipleActionBar.vue' /* webpackChunkName: "components/multiple-action-bar" */).then(c => wrapFunctional(c.default || c))
export const MultipleActionsPanel = () => import('../../components/MultipleActionsPanel.vue' /* webpackChunkName: "components/multiple-actions-panel" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderBlock = () => import('../../components/PlaceholderBlock.vue' /* webpackChunkName: "components/placeholder-block" */).then(c => wrapFunctional(c.default || c))
export const SelectorWithNewOption = () => import('../../components/SelectorWithNewOption.vue' /* webpackChunkName: "components/selector-with-new-option" */).then(c => wrapFunctional(c.default || c))
export const TextChipGenerator = () => import('../../components/TextChipGenerator.vue' /* webpackChunkName: "components/text-chip-generator" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const FormBlock = () => import('../../components/Form/FormBlock.vue' /* webpackChunkName: "components/form-block" */).then(c => wrapFunctional(c.default || c))
export const FormRowGrid = () => import('../../components/Form/FormRowGrid.vue' /* webpackChunkName: "components/form-row-grid" */).then(c => wrapFunctional(c.default || c))
export const DialogsAddBrand = () => import('../../components/Dialogs/AddBrand.vue' /* webpackChunkName: "components/dialogs-add-brand" */).then(c => wrapFunctional(c.default || c))
export const MobileBackBtn = () => import('../../components/Mobile/BackBtn.vue' /* webpackChunkName: "components/mobile-back-btn" */).then(c => wrapFunctional(c.default || c))
export const MobileBottomSheetMenuItem = () => import('../../components/Mobile/BottomSheetMenuItem.vue' /* webpackChunkName: "components/mobile-bottom-sheet-menu-item" */).then(c => wrapFunctional(c.default || c))
export const DatePeriodCalendarWithMenu = () => import('../../components/Date/PeriodCalendarWithMenu.vue' /* webpackChunkName: "components/date-period-calendar-with-menu" */).then(c => wrapFunctional(c.default || c))
export const DateTransparentTabsDate = () => import('../../components/Date/TransparentTabsDate.vue' /* webpackChunkName: "components/date-transparent-tabs-date" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuItem = () => import('../../components/MobileMenuPage/MenuItem.vue' /* webpackChunkName: "components/mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuItemBase = () => import('../../components/MobileMenuPage/MenuItemBase.vue' /* webpackChunkName: "components/mobile-menu-item-base" */).then(c => wrapFunctional(c.default || c))
export const MobileMenuItemsGroupsContainer = () => import('../../components/MobileMenuPage/MenuItemsGroupsContainer.vue' /* webpackChunkName: "components/mobile-menu-items-groups-container" */).then(c => wrapFunctional(c.default || c))
export const BottomSheetHeader = () => import('../../components/BottomSheet/SheetHeader.vue' /* webpackChunkName: "components/bottom-sheet-header" */).then(c => wrapFunctional(c.default || c))
export const BottomSheetHeaderActionable = () => import('../../components/BottomSheet/SheetHeaderActionable.vue' /* webpackChunkName: "components/bottom-sheet-header-actionable" */).then(c => wrapFunctional(c.default || c))
export const TablePartsDiscountPrice = () => import('../../components/TableParts/DiscountPrice.vue' /* webpackChunkName: "components/table-parts-discount-price" */).then(c => wrapFunctional(c.default || c))
export const TablePartsRowTitle = () => import('../../components/TableParts/RowTitle.vue' /* webpackChunkName: "components/table-parts-row-title" */).then(c => wrapFunctional(c.default || c))
export const TablePartsTitleSubtitle = () => import('../../components/TableParts/TitleSubtitle.vue' /* webpackChunkName: "components/table-parts-title-subtitle" */).then(c => wrapFunctional(c.default || c))
export const StatsStatCard = () => import('../../components/Stats/StatCard.vue' /* webpackChunkName: "components/stats-stat-card" */).then(c => wrapFunctional(c.default || c))
export const StatsRow = () => import('../../components/Stats/StatsRow.vue' /* webpackChunkName: "components/stats-row" */).then(c => wrapFunctional(c.default || c))
export const RostCheckboxGroup = () => import('../../components/rost/CheckboxGroup.vue' /* webpackChunkName: "components/rost-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const RostMarkDownEditor = () => import('../../components/rost/MarkDownEditor.vue' /* webpackChunkName: "components/rost-mark-down-editor" */).then(c => wrapFunctional(c.default || c))
export const RostAdaptiveDialog = () => import('../../components/rost/adaptive-dialog.vue' /* webpackChunkName: "components/rost-adaptive-dialog" */).then(c => wrapFunctional(c.default || c))
export const RostAutocomplete = () => import('../../components/rost/autocomplete.vue' /* webpackChunkName: "components/rost-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const RostBreadcrumbs = () => import('../../components/rost/breadcrumbs.vue' /* webpackChunkName: "components/rost-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const RostBtnToggle = () => import('../../components/rost/btn-toggle.vue' /* webpackChunkName: "components/rost-btn-toggle" */).then(c => wrapFunctional(c.default || c))
export const RostBtn = () => import('../../components/rost/btn.vue' /* webpackChunkName: "components/rost-btn" */).then(c => wrapFunctional(c.default || c))
export const RostCard = () => import('../../components/rost/card.vue' /* webpackChunkName: "components/rost-card" */).then(c => wrapFunctional(c.default || c))
export const RostCheckRadioList = () => import('../../components/rost/check-radio-list.vue' /* webpackChunkName: "components/rost-check-radio-list" */).then(c => wrapFunctional(c.default || c))
export const RostCheckbox = () => import('../../components/rost/checkbox.vue' /* webpackChunkName: "components/rost-checkbox" */).then(c => wrapFunctional(c.default || c))
export const RostChip = () => import('../../components/rost/chip.vue' /* webpackChunkName: "components/rost-chip" */).then(c => wrapFunctional(c.default || c))
export const RostCombobox = () => import('../../components/rost/combobox.vue' /* webpackChunkName: "components/rost-combobox" */).then(c => wrapFunctional(c.default || c))
export const RostDatePicker = () => import('../../components/rost/date-picker.vue' /* webpackChunkName: "components/rost-date-picker" */).then(c => wrapFunctional(c.default || c))
export const RostDateTime = () => import('../../components/rost/date-time.vue' /* webpackChunkName: "components/rost-date-time" */).then(c => wrapFunctional(c.default || c))
export const RostDecimalInput = () => import('../../components/rost/decimalInput.vue' /* webpackChunkName: "components/rost-decimal-input" */).then(c => wrapFunctional(c.default || c))
export const RostDialog = () => import('../../components/rost/dialog.vue' /* webpackChunkName: "components/rost-dialog" */).then(c => wrapFunctional(c.default || c))
export const RostHintIcon = () => import('../../components/rost/hint-icon.vue' /* webpackChunkName: "components/rost-hint-icon" */).then(c => wrapFunctional(c.default || c))
export const RostIcon = () => import('../../components/rost/icon.vue' /* webpackChunkName: "components/rost-icon" */).then(c => wrapFunctional(c.default || c))
export const RostInputNumber = () => import('../../components/rost/inputNumber.vue' /* webpackChunkName: "components/rost-input-number" */).then(c => wrapFunctional(c.default || c))
export const RostIntegerInput = () => import('../../components/rost/integerInput.vue' /* webpackChunkName: "components/rost-integer-input" */).then(c => wrapFunctional(c.default || c))
export const RostMaskableInput = () => import('../../components/rost/maskableInput.vue' /* webpackChunkName: "components/rost-maskable-input" */).then(c => wrapFunctional(c.default || c))
export const RostPercentInput = () => import('../../components/rost/percentInput.vue' /* webpackChunkName: "components/rost-percent-input" */).then(c => wrapFunctional(c.default || c))
export const RostPhoneInput = () => import('../../components/rost/phone-input.vue' /* webpackChunkName: "components/rost-phone-input" */).then(c => wrapFunctional(c.default || c))
export const RostRadioGroup = () => import('../../components/rost/radio-group.vue' /* webpackChunkName: "components/rost-radio-group" */).then(c => wrapFunctional(c.default || c))
export const RostRating = () => import('../../components/rost/rating.vue' /* webpackChunkName: "components/rost-rating" */).then(c => wrapFunctional(c.default || c))
export const RostAiGenerate = () => import('../../components/rost/rost-ai-generate.vue' /* webpackChunkName: "components/rost-ai-generate" */).then(c => wrapFunctional(c.default || c))
export const RostRoundedIcon = () => import('../../components/rost/roundedIcon.vue' /* webpackChunkName: "components/rost-rounded-icon" */).then(c => wrapFunctional(c.default || c))
export const RostSelect = () => import('../../components/rost/select.vue' /* webpackChunkName: "components/rost-select" */).then(c => wrapFunctional(c.default || c))
export const RostSwitch = () => import('../../components/rost/switch.vue' /* webpackChunkName: "components/rost-switch" */).then(c => wrapFunctional(c.default || c))
export const RostTable = () => import('../../components/rost/table.vue' /* webpackChunkName: "components/rost-table" */).then(c => wrapFunctional(c.default || c))
export const RostTextField = () => import('../../components/rost/text-field.vue' /* webpackChunkName: "components/rost-text-field" */).then(c => wrapFunctional(c.default || c))
export const RostTextarea = () => import('../../components/rost/textarea.vue' /* webpackChunkName: "components/rost-textarea" */).then(c => wrapFunctional(c.default || c))
export const RostTime = () => import('../../components/rost/time.vue' /* webpackChunkName: "components/rost-time" */).then(c => wrapFunctional(c.default || c))
export const RostToolsBtns = () => import('../../components/rost/tools-btns.vue' /* webpackChunkName: "components/rost-tools-btns" */).then(c => wrapFunctional(c.default || c))
export const RostTransparentTabs = () => import('../../components/rost/transparentTabs.vue' /* webpackChunkName: "components/rost-transparent-tabs" */).then(c => wrapFunctional(c.default || c))
export const FormFormDesignBackgroundContainer = () => import('../../components/Form/FormDesign/BackgroundContainer.vue' /* webpackChunkName: "components/form-form-design-background-container" */).then(c => wrapFunctional(c.default || c))
export const FormInputsGrid = () => import('../../components/Form/FormDesign/FormInputsGrid.vue' /* webpackChunkName: "components/form-inputs-grid" */).then(c => wrapFunctional(c.default || c))
export const FormFormDesignInputWrapper = () => import('../../components/Form/FormDesign/InputWrapper.vue' /* webpackChunkName: "components/form-form-design-input-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputAccessGroupsAndPermissionsInput = () => import('../../components/Form/FormEntityInput/AccessGroupsAndPermissionsInput.vue' /* webpackChunkName: "components/form-form-entity-input-access-groups-and-permissions-input" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputProdcutAutocomplete = () => import('../../components/Form/FormEntityInput/ProdcutAutocomplete.vue' /* webpackChunkName: "components/form-form-entity-input-prodcut-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputUserAutocomplete = () => import('../../components/Form/FormEntityInput/UserAutocomplete.vue' /* webpackChunkName: "components/form-form-entity-input-user-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputUserAutocompleteUserRow = () => import('../../components/Form/FormEntityInput/UserAutocompleteUserRow.vue' /* webpackChunkName: "components/form-form-entity-input-user-autocomplete-user-row" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputUserRoles = () => import('../../components/Form/FormEntityInput/UserRoles.vue' /* webpackChunkName: "components/form-form-entity-input-user-roles" */).then(c => wrapFunctional(c.default || c))
export const FormMultipleImagesPhotoUploader = () => import('../../components/Form/MultipleImages/PhotoUploader.vue' /* webpackChunkName: "components/form-multiple-images-photo-uploader" */).then(c => wrapFunctional(c.default || c))
export const FormMultipleImagesPhotoUploaderPlusPlaceholder = () => import('../../components/Form/MultipleImages/PhotoUploaderPlusPlaceholder.vue' /* webpackChunkName: "components/form-multiple-images-photo-uploader-plus-placeholder" */).then(c => wrapFunctional(c.default || c))
export const FormMultipleImagesSinglePhotoPreview = () => import('../../components/Form/MultipleImages/SinglePhotoPreview.vue' /* webpackChunkName: "components/form-multiple-images-single-photo-preview" */).then(c => wrapFunctional(c.default || c))
export const FormMultipleImagesUploaderHintWrapper = () => import('../../components/Form/MultipleImages/UploaderHintWrapper.vue' /* webpackChunkName: "components/form-multiple-images-uploader-hint-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UserTabsMainTab = () => import('../../components/User/Tabs/MainTab.vue' /* webpackChunkName: "components/user-tabs-main-tab" */).then(c => wrapFunctional(c.default || c))
export const UserTabsPrivacyTab = () => import('../../components/User/Tabs/PrivacyTab.vue' /* webpackChunkName: "components/user-tabs-privacy-tab" */).then(c => wrapFunctional(c.default || c))
export const FormFormEntityInputBaseChipsSelectedInput = () => import('../../components/Form/FormEntityInput/Base/ChipsSelectedInput.vue' /* webpackChunkName: "components/form-form-entity-input-base-chips-selected-input" */).then(c => wrapFunctional(c.default || c))
export const RostFunctionalInfiniteAutocomplete = () => import('../../components/rost/functional/InfiniteAutocomplete.vue' /* webpackChunkName: "components/rost-functional-infinite-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const RostFunctionalServerAutocomplete = () => import('../../components/rost/functional/ServerAutocomplete.vue' /* webpackChunkName: "components/rost-functional-server-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const RostFunctionalServerAutocomppleteUi = () => import('../../components/rost/functional/ServerAutocomppleteUi.vue' /* webpackChunkName: "components/rost-functional-server-autocompplete-ui" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
